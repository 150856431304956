<div *ngIf="accessToken != null">
   loading...
</div>
<div class="login-page" *ngIf="accessToken == null" [ngClass]="showNewBackgroundImage() && currentBrand == 'BMW_MOTORRAD' ? 'login-page-motorrad-italy' : ''">
   <div class="container-logo-login">
      <oas-logo [loginLogo]="true"></oas-logo>
   </div>
   <div *ngIf="loginPageBannerText?.length > 0" class="banner-container">
      <h3 class="banner-title">{{ loginPageBannerTitle }}</h3>
      <span [innerHTML]="loginPageBannerText"></span>
   </div>
   <div class="login-page__mobile"></div>
   <div class="login-page__row-wrapper">
      <div class="login-page__form-wrapper">
         <div class="login-page__form ml-0 p-70">
            <h1 class="login-form__title" [ngClass]="!isMINI() ? ' welcome-header-mb' : 'welcome-header-mb-mini'"  i18n="@@welcomeHeader">
               Welcome {{ currentBrand?.replace("_", " ") }}
            </h1>
            <p class="text-bold" *ngIf="enableWelcomePageSubtitle()"><span i18n="@@welcomeSubtitle">Welcome subtitle {{ currentBrand?.split("_")[0] }}</span></p>
            <p class="text-bold" *ngIf="!isMINI() && !enableWelcomePageSubtitle()" i18n="@@profileLoginBMW">Login in your profile using your BMW ID</p>
            <p class="text-bold-mini" *ngIf="isMINI() && !enableWelcomePageSubtitle()" i18n="@@profileLoginMINI">Login in your profile using your MINI ID</p>
            <form id="login-form">
               <div>
                  <button (click)="performLoginOrRegister('login')" type="submit" class="btn btn-primary btn-block ui-button login-form__submit responsive-button-logi"
                     [ngClass]="!isMINI() ? 'BMWTypeNextBold' : 'btn-like-register'">
                     <span i18n="@@login with BMW ID" *ngIf="!isMINI()">Login with BMW ID</span>
                     <span i18n="@@login with MINI ID" *ngIf="isMINI()">Login with MINI ID</span>
                  </button>
               </div>
               <div>
                  <button (click)="performLoginOrRegister('register')" 
                     type="submit" 
                     class="btn btn-block ui-button login-form__submit responsive-button-logi"
                     [ngClass]="!isMINI() ? 'btn-outline-primary BMWTypeNextBold' : 'btn-outline-primary-mini'">
                     <span i18n="@@register with BMW ID" *ngIf="!isMINI()">Register now</span>
                     <span i18n="@@register with MINI ID" *ngIf="isMINI()" style="text-transform: none !important;">Register now</span>
                  </button>
               </div>
               <div>
                  <button
                     *ngIf="isAppointmentWithoutRegistrationEnabled()"
                     type="button"
                     (click)="changeShowForm()"
                     data-tracking-linkid="local > appointment without registration"
                     class="btn btn-block ui-button login-form__submit responsive-button-login"
                     style="width: 100%;"
                     i18n="@@requestAppointmentWithoutRegistration"
                     [ngClass]="!isMINI() ? 'btn-outline-primary BMWTypeNextBold' : 'btn-outline-primary-mini btn-like-register'"
                  >
                     Appointment without registration
                  </button>
               </div>
               <div *ngIf="local == 'it-IT'">
                  <a class="text-bold text-primary"  *ngIf="!isMINI()" (click)="navigateToLogin()" routerLinkActive="active" i18n="@@logAsDealer">Login dealer</a>
                  <a class="text-bold-mini text-primary"  *ngIf="isMINI()" (click)="navigateToLogin()" routerLinkActive="active" i18n="@@logAsDealer">Login dealer</a>
               </div>
            </form>
         </div>
      </div>
      <!-- modal appontment without registration -->
      <div class="modal-background" *ngIf="showForm" (click)="closeModal()">
         <div class="modal-home" *ngIf="showForm">
            <div class="modal-header">
               <h4 i18n="@@advantageNoQuickAppointment">Advantages of a complete record</h4>
            </div>
            <div class="modal-body">
               <div class="text">
                  <p i18n="@@noQuickAppointmentInfo">
                     You have selected to request an appointment without making a complete record of your data.
                     We inform you that by registering you will obtain the following benefits:
                  </p>
                  <p i18n="@@noQuickAppointmentInfo1">1. Digital profile with the data of your vehicle.</p>
                  <p i18n="@@noQuickAppointmentInfo2">2. Notifications to the telephone / email.</p>
                  <p i18n="@@noQuickAppointmentInfo3">3. Possibility of modifying the online appointment.</p>
               </div>
               <div class="buttons" data-tracking-regionid="online service appointment ">
                  <button
                     (click)="goToQuickAppointment()"
                     class="btn btn-secondary-original btn-block login-form__submit ui-button responsive-button"
                     routerLinkActive="active"
                     i18n="@@withoutRegistration"
                     data-tracking-linkid="local > continue without registration"
                  >
                     Appointment without registration
                  </button>
                  <a
                     [href]="urlBuilder('register')"
                     class="btn btn-primary btn-block login-form__submit ui-button responsive-button"
                     routerLinkActive="active"
                     i18n="@@completeAndBookAppointment"
                     data-tracking-linkid="local > register and book appointment"
                  >
                     Complete registration and book appointment
                  </a>
               </div>
            </div>
         </div>
      </div>
   </div>
</div>
