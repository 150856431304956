import { AfterViewChecked, ChangeDetectorRef, Component, Inject, LOCALE_ID, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { Title } from '@angular/platform-browser';
import {
    NavigationCancel,
    NavigationEnd,
    NavigationError,
    NavigationStart,
    Router,
} from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { CountryConfigurationService } from '../configuration/country-configuration.service';
import { countryConfiguration } from '../configuration/country.configuration';
import { StyleSwitcherService } from './core/style-switcher.service';
import { CountryConfiguration } from './country-configuration';
import { VehicleBrand } from './shared/entities';
import { ScriptService } from './shared/script.service';
import * as moment from 'moment';
import 'moment/locale/it';
import 'moment/locale/pt';
import 'moment/locale/es';
import { HttpClient } from '@angular/common/http';
import { DOCUMENT } from '@angular/common';
import { Meta } from '@angular/platform-browser';

@Component({
    selector: 'oas-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy, AfterViewChecked {
    public loading = false;
    public modalRefs = {};
    public privacyPolicyLink: string;
    public contactLink: string;
    public legalNoteLink: string;
    public textHomeLink: string;
    public homeLink: string;
    public vatNumber: string;
    public hostname: string;
    public brand: string;
    public countryConfiguration: CountryConfiguration;
    public isWelcomeTerminal = false;
    public websiteDomainName: string;
    public ePaasTenantId: string;
    public ePaasEnabled = false;
    public isWelcomePage = false;

    constructor(
        private router: Router,
        private modalService: BsModalService,
        @Inject(LOCALE_ID) public locale: string,
        @Inject(DOCUMENT) private _document: HTMLDocument,
        private styleSwitcherService: StyleSwitcherService,
        private renderer: Renderer2,
        private titleService: Title,
        private scriptService: ScriptService,
        countryConfigurationService: CountryConfigurationService,
        private http: HttpClient,
        private cdref: ChangeDetectorRef,
        private meta: Meta
    ) {
        this.styleSwitcherService.renderer = this.renderer;
        this.hostname = window.location.hostname;
        this.countryConfiguration = countryConfigurationService.getCountryConfiguration();
    }

    public ngOnInit(): void {
        this.router.events.subscribe(event => this.navigationInterceptor(event));

        if (this.hostname.includes('.mini.')) {
            this.switchBrand('MINI');
        } else if (this.hostname.includes('.bmw-motorrad.')) {
            this.switchBrand('BMW_MOTORRAD');
        } else {
            this.switchBrand('BMW');
        }

        this.isWelcomeTerminal = false;

        moment.locale(countryConfiguration.language);

        if (this.ePaasEnabled) {
            this.scriptService.load(true, 'epaas').then(data => {
                window['epaas']?.api.initialize({ tenant: this.ePaasTenantId, locale: this.locale });
            });
        }

        this.scriptService.load(true, 'tracy');
        if(this.countryConfiguration.countryCode === 'ES') {
            const seoBrand = this.brand?.replace("_", " ");
            this.meta.addTag({ name: 'google-site-verification', content: '5GIm6ui_875f0hIiD8NAxfpkrIVR2I--rDKIMIiX-es' });
            this.meta.addTag({ name: 'title', content: seoBrand === 'BMW' ? `Cita taller ${seoBrand}` : `Cita Online ${seoBrand} Service` });
            this.meta.addTag({ name: 'description', content: `Reserva tu cita online en un taller oficial ${seoBrand} de forma rápida y sencilla. Con este servicio, llevar tu ${seoBrand} al taller nunca ha sido tan fácil. ¡Pide cita ahora!` });
            this.meta.addTag({ name: 'keywords', content: `${seoBrand?.toLowerCase()} cita taller, ${seoBrand?.toLowerCase()} cita previa taller, cita online ${seoBrand?.toLowerCase()} taller, cita online taller ${seoBrand?.toLowerCase()}` });
        }
    }
    ngAfterViewChecked() {
        this.cdref.detectChanges();
    }

    public heightDefaultPage(): boolean {
        return window.innerHeight > document.getElementsByTagName('html')[0].offsetHeight;
        // FIXME: find a better way, instead of using VanillaJS
    }

    public switchBrand(brand: VehicleBrand): void {
        this.brand = brand;

        let newBrand: string;
        switch (brand) {
            case <VehicleBrand>'MINI':
                newBrand = 'mini';
                break;
            case <VehicleBrand>'BMW_MOTORRAD':
                newBrand = 'bmw_motorrad';
                break;
            default:
                newBrand = 'bmw';
                break;
        }

        this.styleSwitcherService.changeStyle(newBrand);
        this.titleService.setTitle(brand.replace('_', ' ') + ' Service Check-in');

        this.privacyPolicyLink = countryConfiguration[brand].footer.privacyPolicyLink;
        this.legalNoteLink = countryConfiguration[brand].footer.legalNoteLink;
        this.contactLink = countryConfiguration[brand].footer.contactLink;
        this.textHomeLink = countryConfiguration[brand].footer.textHomeLink;
        this.homeLink = countryConfiguration[brand].footer.homeLink;
        this.vatNumber = countryConfiguration[brand].footer.vatNumber;
        this.ePaasTenantId = countryConfiguration[brand].ePaas.tenantId;
        this.ePaasEnabled = countryConfiguration[brand].ePaas.enabled;

        switch (brand) {
            case 'MINI':
                this._document.getElementById('appFavicon').setAttribute('href', '/favicon-mini.ico');
                break;
            case 'BMW_MOTORRAD':
                this._document.getElementById('appFavicon').setAttribute('href', '/favicon-bmw-motorrad.ico');
                break;
            case 'BMW':
            default:
                this._document.getElementById('appFavicon').setAttribute('href', '/favicon.ico');
                break;
        }
    }

    /**
     * Click handler method to download the DSA pdf file
     */
    public downloadDSAPdf(): void {
        const country = `${this.countryConfiguration.countryCode}`;
        this.http.get(`assets/pdf/${country}/DSA_Notice_${country}.pdf`, { responseType: 'arraybuffer' })
          .subscribe((data: ArrayBuffer) => {
            const blob = new Blob([data], { type: 'application/pdf' });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = `DSA_Notice_${this.countryConfiguration.countryCode}.pdf`;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
          });
      }

    public ngOnDestroy(): void {
        sessionStorage.removeItem('selectedVehicleIndex');
    }

    // Shows and hides the loading spinner during RouterEvent changes
    private navigationInterceptor(event): void {
        if (event instanceof NavigationStart) {
            this.loading = true;
        } else if (event instanceof NavigationEnd) {
            this.loading = false;
        }
        // Set loading state to false in both of the below events to hide the spinner in case a request fails
        else if (event instanceof NavigationCancel || event instanceof NavigationError) {
            this.loading = false;
        }
    }

    public openModal(id: string, template: any, clazz?: string) {
        const params = { class: clazz ? clazz : 'modal-default' };
        this.modalRefs[id] = this.modalService.show(template, params);

        // Fix body paddingRigth
        const paddingModal = document.querySelectorAll('body');
        if (paddingModal.length > 0) {
            paddingModal[0].style.paddingRight = '0px';
        }

        return this.modalRefs[id];
    }

    public openModalConfig(id: string, template: any, config: any, clazz?: string) {
        const params = { class: clazz ? clazz : 'modal-default' };
        this.modalRefs[id] = this.modalService.show(template, Object.assign({}, config, params));
    }

    public closeModal(id: string) {
        const modal = this.modalRefs[id];
        if (modal) {
            modal.hide();
            delete this.modalRefs[id];
        }
    }

    public closeAllModals() {
        for (const modal in this.modalRefs) {
            if (modal) {
                this.closeModal(modal);
            }
        }
    }

    public getModalContnet(id: string): any {
        let result = null;

        if (this.modalRefs[id]) {
            result = this.modalRefs[id].content;
        }

        return result;
    }
}
