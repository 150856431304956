import { CountryConfiguration } from '../app/country-configuration';
import { RoleType } from '../app/shared/entities';
export let spainCountryConfiguration: CountryConfiguration = {
    language: 'es',
    countryCode: 'ES',
    locale: 'es-ES',
    nscCicSearchOnDMS: true,
    welcomeTerminalAppointmentIdenabled: true,
    appointmentCountForSadvInDashboard: false,
    showCarShadowImageInClientSearch:false,
    phoneNumberRegex: /^(([+]|00)34)?[0-9]{1,11}$/,
    mobileNumberRegex: [
        /^(([+]|00)34)?([6-7][0-9]{8}|[8-9][1-9][0-9]{7})$/,
        /^(([+]|00)49)[2-9][0-9]{9,10}$/,
        /^(([+]|00)33)[1-79][0-9]{8}$/,
        /^(([+]|00)212)[0-9]{9}$/,
        /^(([+]|00)350)[0-9]{8}$/,
        /^(([+]|00)351)[2-9][0-9]{8}$/,
        /^(([+]|00)376)?[3-46][0-9]{5}|[7-8][0-9]{5}$/,
        /^(([+]|00)39)[0-9]{9,11}$/,
        /^(([+]|00)41)[0-9]{9}$/,
    ],
    vatRegex: /^(?=.{9}$)\d*[a-zA-Z]\d{0,31}$/g,
    fiscalCodeRegex: null,
    dsaFilePath: 'assets/pdf/ES/DSA_Notice.pdf',
    showDSALink: false,
    BMW: {
        footer: {
            privacyPolicyLink: 'https://www.bmw.es/es/footer/metanavigation/legal-disclaimer/aviso-legal-gdpr.html',
            legalNoteLink: 'https://www.bmw.es/es/footer/metanavigation/legal-disclaimer/aviso-legal.html',
            contactLink: 'mailto:rclientes@bmw.es',
            textHomeLink: 'BMW.es',
            homeLink: 'https://www.bmw.es/es/index.html',
            vatNumber: 'Número de identificación fiscal: A-28713642',
        },
        srpCampaignFootNote:
            '<span>Precios Cerrados con un descuento mínimo garantizado del 20%. Incluye Aceite Genuino, Recambios Originales, mano de obra e impuestos.</span>',
        tssbInfoUrl: 'https://www.bmw.es/es/topics/mundo-bmw/connected-drive/teleservices.html?p=999',
        supportChatAvailable: true,
        quickAppointment: true,
        ePaas: {
            enabled: true,
            tenantId: 'cita-online-taller_bmw_es',
        },
        longLifeCareMobilityBanner: false,
        remoteKeyRead: false,
        remoteKeyReadLink: '',
        enableTugaTicket: false,
        fastLaneEnabled: true,
    },
    MINI: {
        footer: {
            privacyPolicyLink: 'https://www.mini.es/es_ES/home/footer/politica-de-privacidad.html',
            legalNoteLink: 'https://www.mini.es/es_ES/home/footer/texto-legal.html',
            contactLink: 'mailto:rclientes@mini.es',
            textHomeLink: 'MINI.es',
            homeLink: 'https://www.mini.es/es_ES/home.html',
            vatNumber: 'Número de identificación fiscal: A-28713642',
        },
        srpCampaignFootNote:
            '<span>Precios Cerrados con un descuento mínimo garantizado del 20%. Incluye Aceite Genuino, Recambios Originales, mano de obra e impuestos.</span>',
        tssbInfoUrl: 'https://www.mini.es/es_ES/home/services/service-and-repair/teleservices.html',
        supportChatAvailable: true,
        quickAppointment: true,
        ePaas: {
            enabled: true,
            tenantId: 'cita-online-taller_mini_es',
        },
        longLifeCareMobilityBanner: false,
        remoteKeyRead: false,
        remoteKeyReadLink: '',
        enableTugaTicket: false,
        fastLaneEnabled: true,
    },
    BMW_MOTORRAD: {
        footer: {
            privacyPolicyLink: 'https://www.bmw-motorrad.es/es/public-pool/content-pool/privacy-policy.html',
            legalNoteLink: 'https://www.bmw-motorrad.es/es/footer/metanavigation/legal-disclaimer/aviso-legal.html',
            contactLink: 'mailto:rclientes@bmw-motorrad.es',
            textHomeLink: 'BMW-MOTORRAD.es',
            homeLink: 'https://www.bmw-motorrad.es/es_ES/home.html',
            vatNumber: 'Número de identificación fiscal: A-28713642',
        },
        srpCampaignFootNote:
            '<span>Precios Cerrados con un descuento mínimo garantizado del 20%. Incluye Aceite Genuino, Recambios Originales, mano de obra e impuestos.</span>',
        tssbInfoUrl: undefined,
        supportChatAvailable: false,
        quickAppointment: true,
        longLifeCareMobilityBanner: false,
        ePaas: {
            enabled: true,
            tenantId: 'cita-online-taller_bmw-motorrad_es',
        },
        remoteKeyRead: false,
        remoteKeyReadLink: '',
        enableTugaTicket: false,
        fastLaneEnabled: true,
    },
    BMW_I: {
        footer: {
            privacyPolicyLink: 'https://www.bmw.es/es/footer/metanavigation/legal-disclaimer/aviso-legal-gdpr.html',
            legalNoteLink: 'https://www.bmw.es/es/footer/metanavigation/legal-disclaimer/aviso-legal.html',
            contactLink: 'mailto:rclientes@bmw.es',
            textHomeLink: 'BMW.es',
            homeLink: 'https://www.bmw.es/es/index.html',
            vatNumber: 'Número de identificación fiscal: A-28713642',
        },
        srpCampaignFootNote:
            '<span>Precios Cerrados con un descuento mínimo garantizado del 20%. Incluye Aceite Genuino, Recambios Originales, mano de obra e impuestos.</span>',
        tssbInfoUrl: 'https://www.bmw.es/es/footer/footer-section/glosario-bmw/teleservices.html',
        supportChatAvailable: false,
        quickAppointment: true,
        ePaas: {
            enabled: true,
            tenantId: 'cita-online-taller_bmw_es',
        },
        longLifeCareMobilityBanner: false,
        remoteKeyRead: false,
        remoteKeyReadLink: '',
        enableTugaTicket: false,
        fastLaneEnabled: true,
    },
    tssb: {
        enable: true,
        enableRecentPendingAppointmentCheckboxByRoles: new Map<RoleType, boolean>([
            [<RoleType>'ROLE_CIC_EP_MANAGER', false],
            [<RoleType>'ROLE_CIC_EP_USER', false],
            [<RoleType>'ROLE_S_ADV', false],
            [<RoleType>'ROLE_S_ASSISTANT', false],
            [<RoleType>'ROLE_CIC_NSC_ADMIN', false],
            [<RoleType>'ROLE_CIC_NSC_USER', false],
            [<RoleType>'ROLE_ASM', false],
            [<RoleType>'ROLE_RENTING_ADM', false],
            [<RoleType>'ROLE_RENTING_USER', false],
        ]),
        enableEmail: true,
    },
    emailQuickAppointment: 'no-reply-oas-es@bmw.es',
    showAllCapacityTeams: false,
    waitInOutlet: false,
    mobilityStepAvailability: false,
    enableToros: true,
    editCustomer: true,
    vatNumberValidator: '',
    dcomHealth: true,
    showFastLaneLink: true,
    // Quick appointment REFACTOR
    latitude: 40.4885063,
    longitude: -3.6721178000000236,
    createVehicleFiscalCode: false,
    dcomValidation: true,
    mapsRadius: 30000,
    mapsZoom: 10,
    updateDMS: false,
    maxDelay: 72,
    daysOfWeek: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],
    enableRenting: true,
    enableNscReport: true,
    nscReportFilename: 'APPOINTMENTS_DETAILS_FILE_NAME',
    enableSMS: true,
    sendSMS: false,
    automaticSelectionCbs99999: false,
    enableBsi: true,
    hideAutomaticVehicleRecognitionStepIntoAppointmentWithoutRegistration: false,
    enableKiosk: false,
    enablePPVSync: true,
    enableTjekvik: false,
    enableSharebox: false,
    enableSPOA: true,
    enableVinLookup: true,
    preRegistration: {
        enablePreRegistrationFromDms: true,
        enablePreRegistrationFromFastAppointment: true,
        hideCheckBoxWithoutRegistration: false,
        forceSendEmailWithoutRegistration: false,
    },
    showVinOnlineAppointmentResume: false,
    showFiscalNumberVehicleForm: false,
    additionalCountryFavouriteOutlet: 'AD',
    servicesBannerEnableWithoutCampaign: false,
    vehicleFormSmartRegistrationType: 'PLATE',
    showAppointmentWithoutRegistrationVehicleForm: true,
    fastAppointmentMigrationToConsumeCapacity: false,
    dateInputRangeFormat: 'DD/MM/YYYY',
    enableLeadApi: true,
    currencyCode: 'EUR',
    enableEmail: true,
    sendEmail: false,
    leadApiOutlets: [],
    leadApiOutletFilterEnable: true,
    leadTssbAsmEnable: true,
    appointmentFastCustomerCreationGenderEnabled:false,
    eitherPhoneOrEmailEnabled:true,
    showOnlineWorkTeam: false,
    workingDaysEnabled:false,
    dropDownLeadStatuses: [
        'assignedToTsp',
        'contacting',
        'postponed'
    ],
    availabilityFilter: true,
    awp: {
        url: 'https://awp.bmwgroup.com/servicedemand?',
        vinParameter: 'Vin=',
        icon: {
            enabled: true,
            url: 'awp:/start?app=vehicledetails&vin=${vin}',
        }
    },
    enableLeadBattery: true,
    enableFastLaneResume: true,
    enableAwpLinkCustomerSearch: true,
    enableAppointmentShareBox: false,
    enableTuga: false
};
